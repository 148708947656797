<template>
  <div style="padding: 2vw;">
    <div style="z-index: 0; float: right; color: #FFFFFF; background-color: #7030A0; font-size: 2vh; padding: 0.5vh" v-on:click="Logout">Logout</div>
    <div style="z-index: 0; float: right; color: #FFFFFF; background-color: #7030A0; font-size: 2vh; padding: 0.5vh; margin-right: 0.5em;" v-on:click="Krypto">Krypto</div>
    <h1>DepotAnalyse</h1>
    <h2>Performance</h2>
    <div style="overflow: hidden;" v-on:click="updateBlur">
      <div style="width: 50%; float: left;">
        <div style="width: 100%; float: left; text-align: center; font-size: 1em;">Investiert <span style="font-size: 0.7em;">(dav. Gebühren)</span></div>
        <div style="width: 100%; float: left; text-align: center; color: #666666; font-size: 1.5em;" :class="{blur: blur}">
          {{Verlauf.length > 0 ? Geldbetrag(Verlauf.at(-1).Investiert) : '---'}}
          <div style="font-size: 0.5em;">{{Verlauf.length > 0 ? '(' + Geldbetrag(Verlauf.at(-1).Kosten) + ')' : '&nbsp;'}}</div>
        </div>
      </div>
      <div style="width: 50%; float: left;">
        <div style="width: 100%; float: left; text-align: center; font-size: 1em;">Gewinn</div>
        <div style="width: 100%; float: left; text-align: center; font-size: 1.5em;" :class="{loose: Verlauf.length > 0 && Verlauf.at(-1).close + Verlauf.at(-1).Dividende < Verlauf.at(-1).Investiert, win: Verlauf.length > 0 && Verlauf.at(-1).close + Verlauf.at(-1).Dividende > Verlauf.at(-1).Investiert}">
          <span :class="{blur: blur}">{{Verlauf.length > 0 ? Geldbetrag(Verlauf.at(-1).close + Verlauf.at(-1).Dividende - Verlauf.at(-1).Investiert) : '---'}}</span>
          <div style="font-size: 0.5em;">{{Verlauf.length > 0 ? '(' + (((Verlauf.at(-1).close + Verlauf.at(-1).Dividende) / Verlauf.at(-1).Investiert - 1) * 100).toFixed(2) + ' %)' : '&nbsp;'}}</div>
        </div>
      </div>
      <div style="width: 50%; float: left;">
        <div style="width: 100%; float: left; text-align: center; font-size: 1em;">{{letzterTag()}}</div>
        <div
          style="width: 100%; float: left; text-align: center; font-size: 1.5em;"
          :class="{
            loose: Verlauf.length >= 2 && Verlauf.at(-1).close + Verlauf.at(-1).Dividende - Verlauf.at(-2).close - Verlauf.at(-2).Dividende + Verlauf.at(-2).Investiert - Verlauf.at(-1).Investiert < 0,
            win: Verlauf.length >= 2 && Verlauf.at(-1).close + Verlauf.at(-1).Dividende - Verlauf.at(-2).close - Verlauf.at(-2).Dividende + Verlauf.at(-2).Investiert - Verlauf.at(-1).Investiert > 0,
            blur: blur}">
          {{Verlauf.length >= 2 ? Geldbetrag(Verlauf.at(-1).close + Verlauf.at(-1).Dividende - Verlauf.at(-2).close - Verlauf.at(-2).Dividende + Verlauf.at(-2).Investiert - Verlauf.at(-1).Investiert) : '---'}}</div>
      </div>
      <div style="width: 50%; float: left;">
        <div style="width: 100%; float: left; text-align: center; font-size: 1em;">pro Jahr (IZF)</div>
        <div style="width: 100%; float: left; text-align: center; font-size: 1.5em;" :class="{loose: IZF() < 0, win: IZF() > 0}">{{IZF() ? (IZF() * 100).toFixed(1) + ' %' : '---'}}</div>
      </div>
      <div style="width: 100%; float: left; text-align: center; font-size: 2.5em; color: #000000; margin-bottom: max(1vh, 15px);" :class="{blur: blur}">
        {{Verlauf.length > 0 ? Geldbetrag(Verlauf.at(-1).close + Verlauf.at(-1).Dividende) : '---'}}
      </div>
    </div>
    <div style="margin-bottom: 2vh;">
      <div class="diagrammDiv">
        <canvas v-if="Zeitraum === 'Tag'" class="Chart" id="KursGraph"></canvas>
        <canvas v-if="Zeitraum !== 'Tag'" class="Chart" id="performanceGraph"></canvas>
      </div>
      <div id="menu">
        <div class="menuButton" :class="{chosen: Zeitraum == 'Tag'}" v-on:click="ZeitraumÄndern('Tag')" style="border-left-style: hidden;">1D</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'Woche'}" v-on:click="ZeitraumÄndern('Woche')">7D</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'MTD'}" v-on:click="ZeitraumÄndern('MTD')">MTD</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'Monat'}" v-on:click="ZeitraumÄndern('Monat')">1M</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'halbes Jahr'}" v-on:click="ZeitraumÄndern('halbes Jahr')">6M</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'Jahr'}" v-on:click="ZeitraumÄndern('Jahr')">1Y</div>
        <div class="menuButton" :class="{chosen: Zeitraum == 'Maximum'}" v-on:click="ZeitraumÄndern('Maximum')" style="border-right-style: hidden;">MAX</div>
      </div>
      <div id="dateWrapper">
        <input id="dateSelect" type="date" v-model="TagVerlaufDatum" v-on:change="Nachladen" />
      </div>
    </div>
    <h2>Zusammensetzung</h2>
    <div style="float: left; width: 100%;">
      <div style="float: left; margin-bottom: 2vh;" :class="{halfWidth: windowWidth > windowHeight, fullWidth: windowWidth <= windowHeight}">
        <div class="diagrammDiv">
          <canvas class="Chart" style="height: 30vh;" id="ZusammensetzungGraph"></canvas>
        </div>
      </div>
      <div style="float: left; margin-bottom: 2vh;" :class="{halfWidthRight: windowWidth > windowHeight, fullWidth: windowWidth <= windowHeight}">
        <EtfComponent v-for="depotWert in DepotWerte"
                      :key="depotWert.ISIN"
                      :isin="depotWert.ISIN"
                      :Name="Name(depotWert.ISIN)"
                      :Menge="depotWert.Menge"
                      :Kurs="depotWert.Kurs"
                      :Dividende="depotWert.Dividende"
                      :Investiert="depotWert.Investiert"
                      :Realisiert="depotWert.Realisiert"
                      :blur="blur" />
      </div>
    </div>
    <h2>Transaktionen</h2>
    <div style="float: left; width: calc(100% - 3px); border: 1.5px #7030A0 solid; margin-bottom: 5px; border-radius: 3px; text-align: center; font-size: 1.5em; font-weight: bold;" v-on:click="neueTransaktion(null)">+</div>
    <TransaktionComponent v-for="transaktion in Transaktionen.slice(0, nTrans)"
                          v-on:click="neueTransaktion(transaktion.transaktionId)"
                          :key="transaktion.transaktionId"
                          :Name="Name(transaktion.ISIN)"
                          :Menge="transaktion.Menge"
                          :Kurs="transaktion.Kurs"
                          :Kosten="transaktion.Kosten"
                          :Datum="transaktion.Zeit"
                          :blur="blur" />
    <div v-if="nTrans > 7" style="float: left; width: calc(100% - 3px); border: 1.5px #7030A0 solid; margin-bottom: 5px; border-radius: 3px; text-align: center; font-size: 1.5em; font-weight: bold;" v-on:click="nTrans /= 2">⇑</div>
    <div v-if="nTrans < Transaktionen.length" style="float: left; width: calc(100% - 3px); border: 1.5px #7030A0 solid; margin-bottom: 5px; border-radius: 3px; text-align: center; font-size: 1.5em; font-weight: bold;" v-on:click="nTrans *= 2">⇓</div>
    <h2>Dividenden</h2>
    <div style="float: left; width: calc(100% - 3px); border: 1.5px #7030A0 solid; margin-bottom: 5px; border-radius: 3px; text-align: center; font-size: 1.5em; font-weight: bold;" v-on:click="neueDividende(null)">+</div>
    <DividendeComponent v-for="dividende in Dividenden"
                        v-on:click="neueDividende(dividende.dividendeId)"
                        :key="dividende.dividendeId"
                        :Name="Name(dividende.ISIN)"
                        :Menge="dividende.Menge"
                        :Dividende="dividende.Dividende"
                        :ExTag="dividende.ExTag"
                        :Zahltag="dividende.Zahltag"
                        :blur="blur" />
  </div>
</template>

<script>
import { Chart } from 'chart.js/auto'
import axios from 'axios'
import 'chartjs-adapter-moment'

import EtfComponent from '../components/EtfComponent'
import TransaktionComponent from '../components/TransaktionComponent'
import DividendeComponent from '../components/DividendeComponent'

import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'DashboardView',
  components: {
    EtfComponent,
    TransaktionComponent,
    DividendeComponent
  },
  mixins: [globalFunctions],
  data () {
    return {
      blur: false,
      performance: false,
      Zeitraum: null,
      charts: {
        Intraday: null,
        Performance: null,
        Zusammensetzung: null
      },
      colors: ['#3070A0', '#30A070', '#A03070', '#7030A0', '#A07030', '#5297CB', '#52CB97', '#CB5297', '#9752CB', '#CB9752'],
      nTrans: 7,
      windowWidth: null,
      windowHeight: null,
      DepotWerte: null,
      Transaktionen: [],
      Dividenden: [],
      Verlauf: [],
      TagVerlauf: [],
      TagVerlaufDatum: null
    }
  },

  methods: {
    Krypto: function () {
      this.$router.replace('/Krypto')
    },

    reversed: function (array) {
      return array.slice().reverse()
    },

    updateBlur: function () {
      this.blur = !this.blur
      localStorage.setItem('blur', this.blur)
      if (this.Zeitraum !== 'Tag') {
        this.charts.Performance.options.scales.yAxis1.ticks.display = !this.blur
        this.charts.Performance.update()
      }
    },

    Geldbetrag: function (x) {
      if (x === '---' || x === null) return '---'
      return x.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })
    },

    neueTransaktion: function (tId) {
      if (tId) this.$router.push('Transaktion/' + tId)
      else this.$router.push('Transaktion/neu')
    },

    neueDividende: function (dId) {
      if (dId) this.$router.push('Dividende/' + dId)
      else this.$router.push('Dividende/neu')
    },

    Name: function (ISIN) {
      if (this.DepotWerte && Object.prototype.hasOwnProperty.call(this.DepotWerte, ISIN) && this.DepotWerte[ISIN].Name) {
        return this.DepotWerte[ISIN].Name
      } else {
        return ISIN
      }
    },

    Kurzform: function (ISIN) {
      if (this.DepotWerte && Object.prototype.hasOwnProperty.call(this.DepotWerte, ISIN) && this.DepotWerte[ISIN].Kurzform) {
        return this.DepotWerte[ISIN].Kurzform
      } else {
        return ISIN
      }
    },

    ZeitraumInTage: function (Zeit) {
      const datum = new Date()
      switch (Zeit) {
        case 'Tag':
          return 1
        case 'Woche':
          return 7
        case 'MTD':
          if (datum.getDate() <= 5) {
            return new Date(datum.getTime() - datum.getDate() * 24 * 60 * 60 * 1000).getDate() + datum.getDate() - 1
          } else {
            return datum.getDate() - 1
          }
        case 'Monat':
          return 31
        case 'halbes Jahr':
          return 183
        case 'Jahr':
          return 365
        case 'Maximum':
          return null
      }
      return null
    },

    ZeitraumÄndern: function (t) {
      const abschnitt = document.getElementById('dateWrapper')
      if (t === 'Tag') {
        if (this.Zeitraum !== 'Tag' && this.charts.Performance) {
          // this.charts.Performance.destroy()
          this.charts.Performance = null
        }
        abschnitt.style.maxHeight = 2 * abschnitt.firstChild.scrollHeight + 'px'
      } else {
        if (this.Zeitraum === 'Tag' && this.charts.Intraday) {
          // this.charts.Intraday.destroy()
          this.charts.Intraday = null
          this.TagVerlaufDatum = null
        }
        abschnitt.style.maxHeight = '0'
      }
      if (t === this.Zeitraum && t !== 'Tag') {
        this.performance = !this.performance
        localStorage.setItem('performance', this.performance)
        this.VerlaufDarstellen()
        return null
      }
      this.Zeitraum = t
      if (t) {
        localStorage.setItem('Zeitraum', t)
      } else {
        localStorage.removeItem('Zeitraum')
      }
      this.Laden()
    },

    IZF: function (w = 0.05, precision = 3) {
      if (this.Verlauf.length <= 1 || this.Transaktionen.length === 0) return null

      let opt = null
      let abw = null
      for (let testZins = -w; testZins <= w; testZins = Math.round(testZins * Math.pow(10, precision) + 1) / Math.pow(10, precision)) {
        if (testZins + 1 < 0) continue
        let sum = 0
        this.Transaktionen.forEach((transaktion) => {
          if (Date.now() > transaktion.Zeit.getTime()) {
            const time = (Date.now() - transaktion.Zeit.getTime()) / (1000 * 60 * 60 * 24 * 365.25)
            const value = Math.round((transaktion.Menge * transaktion.Kurs + transaktion.Kosten) * 100) / 100
            sum -= value * Math.pow(1 + testZins, time)
          }
        })
        sum += this.Verlauf.at(-1).close + this.Verlauf.at(-1).Dividende
        if (opt === null || Math.abs(abw) > Math.abs(sum)) {
          opt = testZins
          abw = sum
        }
      }
      if (Math.round(Math.abs(opt) * Math.pow(10, precision)) >= Math.round(Math.abs(w) * Math.pow(10, precision))) {
        return this.IZF(2 * w)
      }
      return opt
    },

    ZusammensetzungLaden: function () {
      axios.post(this.dataURL(), {
        Funktion: 'Zusammensetzung',
        Name: this.Benutzer(),
        Passwort: this.Passwort()
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (!Array.isArray(response.data)) {
          setTimeout(this.ZusammensetzungLaden, 1000)
          return null
        }
        const DepotWerte = {}
        response.data.forEach(position => {
          if (position.Menge === 0) return
          DepotWerte[position.ISIN] = {
            ISIN: position.ISIN,
            Name: position.Name,
            Kurzform: position.Kurzform,
            Menge: parseFloat(position.Menge),
            Kurs: parseFloat(position.KursAktuell),
            Dividende: position.Dividende ? parseFloat(position.Dividende) : 0,
            Investiert: parseFloat(position.Investiert),
            Realisiert: parseFloat(position.Realisiert)
          }
        })
        this.DepotWerte = DepotWerte
        this.ZusammensetzungDarstellen()
      })
    },

    ZusammensetzungDarstellen: function () {
      // Daten formatieren
      const dataset = { labels: [], datasets: [{ data: [], backgroundColor: this.colors }] }
      for (const key in this.DepotWerte) {
        dataset.labels.push(this.DepotWerte[key].Kurzform)
        dataset.datasets[0].data.push(this.DepotWerte[key].Menge * this.DepotWerte[key].Kurs)
      }

      if (this.charts.Zusammensetzung) {
        this.charts.Zusammensetzung.data = dataset
        this.charts.Zusammensetzung.update()
        return null
      }
      // eslint-disable-next-line no-unused-vars
      const myChart = document.getElementById('ZusammensetzungGraph').getContext('2d')
      const chart = new Chart(myChart, {
        type: 'doughnut',
        data: dataset,
        options: {
          animation: true,
          maintainAspectRatio: false,
          responsive: true,
          borderWidth: 0,
          hoverOffset: 10,
          layout: {
            padding: 5
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (this.blur) return (context.raw / this.Verlauf.at(-1).close * 100).toFixed(1) + ' %'
                  return [this.Geldbetrag(context.raw), (context.raw / this.Verlauf.at(-1).close * 100).toFixed(1) + ' %']
                }
              },
              titleColor: '#7030A0',
              titleAlign: 'center',
              backgroundColor: '#F2EAF9',
              bodyColor: '#7030A0',
              bodyAlign: 'center'
            },
            legend: {
              display: false
            }
          }
        }
      })
      Object.seal(chart)
      this.charts.Zusammensetzung = chart
    },

    TransaktionenLaden: function () {
      axios.post(this.dataURL(), {
        Funktion: 'Transaktionen',
        Name: this.Benutzer(),
        Passwort: this.Passwort()
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (!Array.isArray(response.data)) {
          setTimeout(this.TransaktionenLaden, 1000)
          return null
        }
        const Transaktionen = []
        response.data.forEach(transaktion => {
          Transaktionen.push({
            transaktionId: transaktion.transaktionId,
            Zeit: new Date(transaktion.Zeit),
            ISIN: transaktion.ISIN,
            Menge: parseFloat(transaktion.Menge),
            Kurs: parseFloat(transaktion.Kurs),
            Kosten: parseFloat(transaktion.Kosten)
          })
        })
        this.Transaktionen = Transaktionen
      })
    },

    DividendenLaden: function () {
      axios.post(this.dataURL(), {
        Funktion: 'Dividenden',
        Name: this.Benutzer(),
        Passwort: this.Passwort()
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (!Array.isArray(response.data)) {
          setTimeout(this.DividendenLaden, 1000)
          return null
        }
        const Dividenden = []
        response.data.forEach(dividende => {
          Dividenden.push({
            dividendeId: dividende.dividendeId,
            ExTag: new Date(dividende.ExTag),
            Zahltag: new Date(dividende.Zahltag),
            ISIN: dividende.ISIN,
            Menge: parseFloat(dividende.Menge),
            Dividende: parseFloat(dividende.Dividende)
          })
        })
        this.Dividenden = Dividenden
      })
    },

    async VerlaufLaden () {
      await axios.post(this.dataURL(), {
        Funktion: 'TageKonten',
        Name: this.Benutzer(),
        Passwort: this.Passwort(),
        Zeitraum: this.Zeitraum ? this.ZeitraumInTage(this.Zeitraum) : null
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (!Array.isArray(response.data)) {
          setTimeout(this.VerlaufLaden, 1000)
          return null
        }
        const Verlauf = []
        response.data.forEach(kurs => {
          Verlauf.push({
            Datum: new Date(kurs.Datum),
            close: parseFloat(kurs.close),
            Investiert: parseFloat(kurs.Investiert),
            Kosten: parseFloat(kurs.Kosten),
            Änderung: parseFloat(kurs.Änderung),
            Dividende: kurs.Dividende ? parseFloat(kurs.Dividende) : 0
          })
        })
        this.Verlauf = Verlauf
        this.updateLocalStorage()
        if (this.Zeitraum !== 'Tag') this.VerlaufDarstellen()
      })
    },

    VerlaufNachladen: function () {
      axios.post(this.dataURL(), {
        Funktion: 'TageKonten',
        Name: this.Benutzer(),
        Passwort: this.Passwort(),
        Zeitraum: 0
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (!Array.isArray(response.data)) {
          setTimeout(this.VerlaufNachladen, 1000)
          return null
        }
        if (this.Verlauf.at(-1).Datum.getTime() === new Date(response.data[0].Datum).getTime()) {
          this.Verlauf.at(-1).close = parseFloat(response.data[0].close)
          this.Verlauf.at(-1).Investiert = parseFloat(response.data[0].Investiert)
          this.Verlauf.at(-1).Kosten = parseFloat(response.data[0].Kosten)
          this.Verlauf.at(-1).Änderung = parseFloat(response.data[0].Änderung)
          this.Verlauf.at(-1).Dividende = response.data[0].Dividende ? parseFloat(response.data[0].Dividende) : 0
        }
        this.updateLocalStorage()
        if (this.Zeitraum !== 'Tag') this.VerlaufDarstellen()
      })
    },

    VerlaufDarstellen: function () {
      if (this.Verlauf.length === 0) return null
      // Daten formatieren
      const Verlauf = this.reversed(this.Verlauf)

      if (this.charts.Performance) {
        this.charts.Performance.data.datasets[0].data = Verlauf.map(tag => { return { x: tag.Datum, y: tag.close + tag.Dividende } })
        this.charts.Performance.data.datasets[1].data = Verlauf.map(tag => { return { x: tag.Datum, y: tag.Investiert } })
        this.charts.Performance.data.datasets[2].data = Verlauf.map(tag => { return { x: tag.Datum, y: tag.Änderung * 100 } })
        this.charts.Performance.data.datasets[3].data = Verlauf.map(tag => { return { x: tag.Datum, y: 100 * (tag.close + tag.Dividende - tag.Investiert) / tag.Investiert } })
        this.charts.Performance.data.datasets[2].backgroundColor = Verlauf.map(tag => { return tag.Änderung > 0 ? 'rgba(48, 160, 112, 0.75)' : 'rgba(160, 48, 112, 0.75)' })
        this.charts.Performance.data.datasets[2].hidden = this.performance
        this.charts.Performance.data.datasets[3].hidden = !this.performance

        this.charts.Performance.update()
        return null
      }
      /* eslint-disable prefer-const */
      let myChart
      myChart = document.getElementById('performanceGraph')
      if (myChart == null) return null
      const chart = new Chart(myChart.getContext('2d'), {
        type: 'scatter',
        data: {
          datasets: [{
            data: Verlauf.map(tag => { return { x: tag.Datum, y: tag.close + tag.Dividende } }),
            showLine: true,
            pointRadius: 0,
            xAxisID: 'xAxis',
            yAxisID: 'yAxis1',
            borderColor: 'rgb(112, 48, 160)',
            borderWidth: 2
          }, {
            data: Verlauf.map(tag => { return { x: tag.Datum, y: tag.Investiert } }),
            showLine: true,
            pointRadius: 0,
            xAxisID: 'xAxis',
            yAxisID: 'yAxis1',
            borderColor: 'rgb(100, 100, 100)',
            borderWidth: 2
          }, {
            data: Verlauf.map(tag => { return { x: tag.Datum, y: tag.Änderung * 100 } }),
            showLine: true,
            hidden: this.performance,
            pointRadius: 0,
            xAxisID: 'xAxis',
            yAxisID: 'yAxis2',
            backgroundColor: Verlauf.map(tag => { return tag.Änderung > 0 ? 'rgba(48, 160, 112, 0.75)' : 'rgba(160, 48, 112, 0.75)' }),
            type: 'bar',
            barPercentage: 1,
            categoryPercentage: 1
          }, {
            data: Verlauf.map(tag => { return { x: tag.Datum, y: 100 * (tag.close + tag.Dividende - tag.Investiert) / tag.Investiert } }),
            borderWidth: 0,
            type: 'line',
            fill: true,
            hidden: !this.performance,
            pointRadius: 0,
            xAxisID: 'xAxis',
            yAxisID: 'yAxis2',
            backgroundColor: 'rgba(112, 48, 160, 0.2)'
          }]
        },
        options: {
          animation: false,
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            tooltip: {
              mode: 'index',
              intersect: false,
              displayColors: false,
              callbacks: {
                label: (context) => {
                  if (this.blur) {
                    switch (context.datasetIndex) {
                      case 2:
                      case 3: {
                        if (isNaN(context.raw.y)) return ''
                        let val = parseFloat(context.raw.y).toFixed(2)
                        if (val >= 0) val = '+' + val
                        return '=> ' + val + '%'
                      }
                      default:
                        return null
                    }
                  } else {
                    const name = ['Schlusskurs', 'Investiert', '', 'Performance'][context.datasetIndex]
                    if (context.datasetIndex === 2 || context.datasetIndex === 3) {
                      if (isNaN(context.raw.y)) return ''
                      let val = parseFloat(context.raw.y).toFixed(2)
                      if (val >= 0) val = '+' + val
                      return '=> ' + val + '%'
                    }
                    return name + ': ' + this.Geldbetrag(context.raw.y)
                  }
                },
                title: (item) => {
                  return item[0].raw.x.toLocaleDateString()
                }
              },
              titleColor: '#7030A0',
              titleAlign: 'center',
              backgroundColor: '#F2EAF9',
              bodyColor: '#7030A0',
              bodyAlign: 'center'
            },
            legend: {
              display: false
            }
          },
          elements: {
            line: {
              borderWidth: 1.5,
              tension: 0,
              fill: false
            }
          },
          scales: {
            yAxis1: {
              position: 'left',
              ticks: {
                display: !this.blur
              }
            },
            yAxis2: {
              position: 'right',
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                lineWidth: 0,
                zeroLineWidth: 0.5
              }
            },
            xAxis: {
              type: 'timeseries',
              time: {
                parser: 'DD.MM.YYYY',
                displayFormats: {
                  day: 'D.M.',
                  week: 'D.M.',
                  month: 'D.M.',
                  quarter: 'M/YY',
                  year: 'YYYY'
                },
                tooltipFormat: 'D.M.YYYY',
                unit: 'day'
              }
            }
          }
        }
      })
      Object.seal(chart)
      this.charts.Performance = chart
    },

    TagLaden: function () {
      axios.post(this.dataURL(), {
        Funktion: 'TagKurse',
        Name: this.Benutzer(),
        Passwort: this.Passwort(),
        Datum: this.TagVerlaufDatum
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (typeof response.data !== 'object') {
          setTimeout(this.TagLaden, 1000)
          return null
        }
        const TagVerlauf = []
        const Kurse = response.data.Kurse
        const n0 = []
        const KurseAktuell = []
        const MengenAktuell = []
        let i = 0
        response.data.Zeiten.forEach(zeit => {
          while (i < Kurse.length && new Date(Kurse[i].Zeit) <= new Date(zeit.Zeit)) {
            if (!Object.prototype.hasOwnProperty.call(n0, Kurse[i].ISIN)) n0[Kurse[i].ISIN] = Kurse[i].Kurs
            KurseAktuell[Kurse[i].ISIN] = Kurse[i].Kurs
            MengenAktuell[Kurse[i].ISIN] = Kurse[i].Menge
            i++
          }
          for (const isin in KurseAktuell) {
            if (!Object.prototype.hasOwnProperty.call(TagVerlauf, isin)) TagVerlauf[isin] = []
            TagVerlauf[isin].push({
              Zeit: new Date(zeit.Zeit),
              Kurs: parseFloat(KurseAktuell[isin]),
              Wert: parseFloat(MengenAktuell[isin] * KurseAktuell[isin]),
              n0: parseFloat(n0[isin])
            })
          }
        })
        this.TagVerlauf = TagVerlauf
        this.TagVerlaufDatum = response.data.Datum
        this.TagDarstellen()
      })
    },

    TagDarstellen: function () {
      // Daten formatieren
      let Datum = this.TagVerlauf[Object.keys(this.TagVerlauf)[0]].at(-1).Zeit
      Datum = new Date((1000 * 60 * 60 * 24) * Math.floor((Datum.getTime() - Datum.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24)))
      Datum = new Date(Datum.getTime() + Datum.getTimezoneOffset() * 60 * 1000)

      const datasets = []
      for (const position in this.TagVerlauf) {
        const n0 = this.TagVerlauf[position][0].n0
        datasets.push({
          data: this.TagVerlauf[position].map(kurs => { return { x: kurs.Zeit, y: kurs.Kurs / n0 } }),
          label: this.Kurzform(position),
          showLine: true,
          pointRadius: 0,
          borderWidth: 2,
          Wert: this.TagVerlauf[position][0].Wert,
          n0: n0,
          xAxisID: 'xAxis',
          yAxisID: 'yAxis'
        })
      }
      datasets.sort((a, b) => {
        return (b.Wert - a.Wert)
      })
      for (const i in datasets) {
        datasets[i].borderColor = this.colors[i % this.colors.length]
        if (i < 3) {
          datasets[i].hidden = false
        } else {
          datasets[i].hidden = true
        }
        delete datasets[i].Wert
      }

      if (this.charts.Intraday) {
        let n = 0
        for (const j in this.charts.Intraday.data.datasets) {
          if (this.charts.Intraday.data.datasets[j].label === datasets[j].label) {
            this.charts.Intraday.data.datasets[j].data = datasets[j].data
            n++
          }
        }
        if (n < datasets.length) this.charts.Intraday.data.datasets = datasets
        this.charts.Intraday.options.scales.xAxis.min = Datum.getTime() + 8 * 60 * 60 * 1000
        this.charts.Intraday.options.scales.xAxis.max = Datum.getTime() + 22 * 60 * 60 * 1000
        this.charts.Intraday.update()
        return null
      }
      // eslint-disable-next-line no-unused-vars
      const myChart = document.getElementById('KursGraph')
      if (myChart == null) return null
      const chart = new Chart(myChart.getContext('2d'), {
        type: 'scatter',
        data: {
          datasets: datasets
        },
        options: {
          animation: false,
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            xAxis: {
              min: Datum.getTime() + 8 * 60 * 60 * 1000,
              max: Datum.getTime() + 22 * 60 * 60 * 1000,
              type: 'time',
              time: {
                parser: 'DD.MM.YYYY HH:mm:ss',
                displayFormats: {
                  millisecond: 'HH:mm',
                  second: 'HH:mm',
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'HH:mm'
                },
                tooltipFormat: 'HH:mm',
                stepSize: 1,
                unit: 'hour'
              }
            },
            yAxis: {
              ticks: {
                callback: function (value) {
                  return (value * 100).toFixed(1) + ' %'
                }
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: (item) => {
                  const name = item.dataset.label
                  const value = item.dataset.n0 * item.raw.y
                  return name + ': ' + this.Geldbetrag(value)
                },
                title: (item) => {
                  return item[0].raw.x.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                }
              },
              titleColor: '#7030A0',
              titleAlign: 'center',
              backgroundColor: '#F2EAF9',
              bodyColor: '#7030A0',
              bodyAlign: 'center'
            }
          }
        }
      })
      Object.seal(chart)
      this.charts.Intraday = chart
    },

    async Laden () {
      await this.VerlaufLaden()
      this.ZusammensetzungLaden()
      this.TransaktionenLaden()
      this.DividendenLaden()
      if (this.Zeitraum === 'Tag') this.TagLaden()
    },

    async Nachladen () {
      if (this.Verlauf.length > 2) {
        this.VerlaufNachladen()
      } else {
        await this.VerlaufLaden()
      }
      this.ZusammensetzungLaden()
      if (this.Transaktionen.length === 0) this.TransaktionenLaden()
      if (this.Dividenden.length === 0) this.DividendenLaden()
      if (this.Zeitraum === 'Tag') this.TagLaden()
    },

    updateLocalStorage: function () {
      localStorage.Verlauf = JSON.stringify([this.Verlauf.at(-2), this.Verlauf.at(-1)])
      localStorage.VerlaufTime = new Date()
    },

    letzterTag: function () {
      if (this.Verlauf.length === 0) {
        return null
      }
      const verlaufDay = new Date(this.Verlauf.at(-1).Datum)
      const verlaufDayNumber = Math.floor(verlaufDay.getTime() / (24 * 60 * 60 * 1000))
      let nowDay = new Date()
      nowDay = new Date(nowDay.getTime() - nowDay.getTimezoneOffset() * 60 * 1000)
      const nowDayNumber = Math.floor(nowDay.getTime() / (24 * 60 * 60 * 1000))

      let Output = ''
      if (nowDayNumber === verlaufDayNumber) {
        Output = 'Heute'
      } else if (nowDayNumber === verlaufDayNumber + 1) {
        Output = 'Gestern'
      } else if (nowDayNumber < verlaufDayNumber + 7) {
        Output = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][verlaufDay.getDay()]
      } else {
        return verlaufDay.getDate() + '.' + (verlaufDay.getMonth() + 1) + '.' + (verlaufDay.getYear() + 1900)
      }

      const loadTime = new Date(localStorage.VerlaufTime)
      if (loadTime.getTime() - verlaufDay.getTime() - verlaufDay.getTimezoneOffset() * 60 * 1000 > 22 * 60 * 60 * 1000) {
        return Output + ' (22:00)'
      } else if (new Date().getTime() - loadTime.getTime() > 5 * 60 * 1000) {
        if (loadTime.getMinutes() < 10) {
          return Output + ' (' + loadTime.getHours() + ':0' + loadTime.getMinutes() + ')'
        } else {
          return Output + ' (' + loadTime.getHours() + ':' + loadTime.getMinutes() + ')'
        }
      } else {
        return Output
      }
    }
  },

  computed: {

  },

  mounted: function () {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    })
    this.blur = localStorage.getItem('blur') === 'true'
    this.performance = localStorage.getItem('performance') === 'true'

    if (localStorage.Verlauf) {
      this.Verlauf = JSON.parse(localStorage.Verlauf)
    }

    if (localStorage.getItem('Zeitraum')) {
      this.ZeitraumÄndern(localStorage.getItem('Zeitraum'))
    } else {
      this.ZeitraumÄndern('MTD')
    }

    setInterval(() => {
      if (new Date().getDay() >= 1 && new Date().getDay() <= 5 && new Date().getHours() >= 8 && new Date().getHours() < 22) {
        this.Nachladen()
      }
    }, 60 * 1000)

    setInterval(() => {
      if (new Date().getDay() < 1 || new Date().getDay() > 5 || new Date().getHours() < 8 || new Date().getHours() >= 22) {
        this.Nachladen()
      }
    }, 10 * 60 * 1000)
  }
}
</script>

<style>
  .diagrammDiv {
    width: calc(100% - 2vw - 10px);
    background-color: #FFFFFF;
    border: 1vw solid #7030A0;
    padding: 5px;
    height: 35vh;
  }

  #menu {
    width: calc(100% - 2vw);
    background-color: #FFFFFF;
    border: solid #7030A0 1vw;
    border-top: 0;
    display: block;
    overflow: auto;
  }

  .menuButton {
    width: calc(100% / 7 - 12px);
    float: left;
    text-align: center;
    font-weight: bold;
    border-width: 1px;
    border-style: hidden solid;
    border-color: #7030A0;
    padding: 5px;
    cursor: pointer;
  }

  #dateWrapper {
    overflow: hidden;
    max-height: 0;
    transition: all 1s;
    width: 100%;
  }

  #dateSelect {
    width: calc(100% - 2vw - 10px);
    border: 1vw solid #7030A0;
    border-top: 0;
    text-align: center;
    color: #7030A0;
    padding: 5px;
    margin: 0;
  }

  .chosen {
    text-decoration: underline;
  }

  .loose {
    color: #b3598c;
  }

  .win {
    color: #59b38c;
  }

  .blur {
    filter: blur(15px);
  }

  .halfWidth {
    width: 50%;
  }

  .fullWidth {
    width: 100%;
  }

  .halfWidthRight {
     width: calc(50% - 1em);
     margin-left: 1em;
  }
</style>
