<template>
  <div class="ETF">
    <div id="nameField" v-on:click="copyIsin">
      <div id="ISIN">
        {{isin}}
      </div>
      <div id="Name">
        {{Name.replace('iShares Core', 'iSH').replace('Amundi', 'ANI')}}
      </div>
    </div>
    <div id="Details">
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap;" :class="{blur: blur}">
        {{Menge.toFixed(3)}}<span style="opacity: 0;"> €</span>
      </div>
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap;">
        x {{Geldbetrag(Kurs)}}
      </div>
      <div v-if="Dividende + Realisiert > 0" style="float: left; width: 100%; overflow: hidden; white-space: nowrap;" :class="{blur: blur}">
        + {{Geldbetrag(Dividende + Realisiert)}}
      </div>
    </div>
    <div id="Wert" @mouseover="hover = !blur && true" @mouseleave="hover = false">
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap; font-size: 0.7em;">
        <span :class="{win: Menge * Kurs + Dividende + Realisiert > Investiert, loose: Menge * Kurs + Dividende + Realisiert < Investiert}">
          <span v-if="Menge * Kurs + Dividende + Realisiert > Investiert">➚ +</span><span v-if="Menge * Kurs + Dividende + Realisiert < Investiert">➘ </span><span v-if="!hover">{{(((Menge * Kurs + Dividende + Realisiert) / Investiert - 1) * 100).toFixed(2)}} %</span><span v-if="hover">{{Geldbetrag(Menge * Kurs + Dividende - Investiert + Realisiert)}}</span>
        </span>
      </div>
      <div style="float: left; width: 100%; overflow: hidden; white-space: nowrap; font-weight: bold;" :class="{blur: blur}">
        {{Geldbetrag(Menge * Kurs + Dividende + Realisiert)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EtfComponent',
  props: {
    isin: { type: String },
    Name: { type: String, default: '' },
    Menge: { type: Number },
    Kurs: { type: Number },
    Dividende: { type: Number, default: 0 },
    Investiert: { type: Number },
    Realisiert: { type: Number },
    blur: { type: Boolean }
  },

  data () {
    return {
      hover: false
    }
  },

  methods: {
    copyIsin: function () {
      navigator.clipboard.writeText(this.isin)
    },

    Geldbetrag: function (x) {
      if (x === '---' || x === null) return '---'
      return x.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })
    }
  },

  mounted: function () {

  }
}
</script>

<style scoped>

div {
  margin: 0;
  padding: 0;
}

.ETF {
  float: left;
  width: calc(100% - 3px - 0.5em);
  border: 1.5px #7030A0 solid;
  padding: 0.25em;
  margin-bottom: 5px;
  border-radius: 3px;
  position: relative;
  font-size: 0.9em;
  cursor: pointer;
}

#ISIN {
  color: #AAAAAA;
  width: 100%;
  float: left;
  font-size: 0.8em;
}

#Name {
  width: 100%;
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.2em;
}

#nameField {
  width: 47%;
  float: left;
}

#Details {
  width: 18%;
  float: left;
  text-align: right;
  opacity: 0.5;
  font-size: 0.8em;
}

#Wert {
  width: 35%;
  float: left;
  font-size: 1.35em;
  text-align: right;
}

.win {
  background: rgba(48, 160, 112, 0.2);
  color: #30A070;
  border-radius: 0.3em;
}

.loose {
  background: rgba(160, 48, 112, 0.2);
  color: #A03070;
  border-radius: 0.3em;
}

</style>
